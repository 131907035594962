<template>
  <b-col md="4">
    <div v-if="preOrderAzProduct.az_product">
      Asin : {{ preOrderAzProduct.az_product.merchant_sku }}
    </div>
    <div>
      SKU : {{ preOrderAzProduct.product_mp_id }}
    </div>

  </b-col>
</template>

<script>

export default {
  name: "PreOrderAzProduct",
  props: {
    preOrder: {
      type: Object,
      required: true,
    },
    preOrderAzProduct: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>
