<template>
  <b-col md="12" class="mt-2">
    <div v-if="preOrder.pre_order_status_id" @dblclick="preOrder.pre_order_status_id=null">
      <b-badge
          :variant="preOrderStatus.color"
      >
        {{ preOrderStatus.name }}
      </b-badge>
    </div>

    <div v-else>
      <v-select
          label="name"
          :options="_preOrderStatuses"
          :reduce="item => item.id"
          class="select-size-sm"
          placeholder="Ön Sipariş Statüsü"
          @option:selected="selectPreOrderStatus"
      />
    </div>
  </b-col>
</template>

<script>

import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "PreOrderStatus",
  components: {vSelect},
  props: {
    preOrder: {
      required: true,
      type: Object
    },
    preOrderStatus: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...mapGetters('preOrderStatus', ['_preOrderStatuses']),
  },
  methods: {
    selectPreOrderStatus(status) {
      this.$store.dispatch('preOrder/putPreOrder', {
        id: this.preOrder.id,
        pre_order_status_id: status.id
      })
          .then(res => {
            if (res.status == 'success') {
              this.preOrder.pre_order_status_id = status.id;
              this.preOrder.status = status
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
