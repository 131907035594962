<template>
  <b-col md="12">
    <div v-if="preOrder.supplier_id" @dblclick="preOrder.supplier_id=null">
      <b-badge>{{ preOrderSupplier.name }}</b-badge>
      <br>
      <div class="mt-2">
        Fiyatı : {{ preOrder.price }} <br>
        Stok : {{ preOrder.amount }}
      </div>
    </div>
    <div v-else>
      <v-select
          label="name"
          :options="_suppliers"
          :reduce="item => item.id"
          class="select-size-sm"
          placeholder="Tedarikçi"
          @option:selected="selectPreOrderSupplier"
      />
    </div>
  </b-col>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "PreOrderSupplier",
  components: {vSelect},
  props: {
    preOrder: {
      type: Object,
      required: true,
    },
    preOrderSupplier: {
      type: Object,
      default: {},
    }
  },
  computed: {
    ...mapGetters('supplier', ['_suppliers'])
  },
  methods: {
    selectPreOrderSupplier(supplier) {
      this.$store.dispatch('preOrder/putPreOrder', {
        id: this.preOrder.id,
        supplier_id: supplier.id
      })
          .then(res => {
            if (res.status == 'success') {
              this.preOrder.supplier_id = supplier.id;
              this.preOrder.supplier = supplier
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
