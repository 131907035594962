<template>
  <b-card>
    <b-row>
      <pre-order-basket :pre-order="preOrder"/>
      <pre-order-product
          :pre-order="preOrder"
          :pre-order-product="preOrder.product"
          :pre-order-user="preOrder.user"
      />

      <b-col md="1">
        <b-row>
          <pre-order-supplier
              :pre-order="preOrder"
              :pre-order-supplier="preOrder.supplier"/>
          <pre-order-status
              :pre-order="preOrder"
              :pre-order-status="preOrder.status"
          />
        </b-row>
      </b-col>

      <pre-order-supplies :pre-order-supplies="preOrder.supplies" :pre-order="preOrder"/>

      <pre-order-az-product
          :pre-order="preOrder"
          :pre-order-az-product="preOrder.az_store_product"
      />
    </b-row>

  </b-card>
</template>

<script>
import PreOrderProduct from "@/views/amazon/pre-order/PreOrderProduct.vue";
import PreOrderSupplier from "@/views/amazon/pre-order/PreOrderSupplier.vue";
import PreOrderStatus from "@/views/amazon/pre-order/PreOrderStatus.vue";
import PreOrderAzProduct from "@/views/amazon/pre-order/PreOrderAzProduct.vue";
import PreOrderBasket from "@/views/amazon/pre-order/PreOrderBasket.vue";
import PreOrderSupplies from "@/views/amazon/pre-order/PreOrderSupplies.vue";

export default {
  name: "PreOrderOrder",
  components: {PreOrderBasket, PreOrderProduct, PreOrderSupplier, PreOrderStatus, PreOrderAzProduct, PreOrderSupplies},
  props: {
    preOrder: {
      type: Object,
      required: true
    }
  },

}
</script>

<style scoped>

</style>
