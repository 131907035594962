<template>
  <b-overlay :show="show" rounded="sm">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="11">
            <b-row>
              <b-col md="2">
                <v-select
                    label="name"
                    v-model="_filter.supplier_ids"
                    :options="_suppliers"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Tedarikçi"
                    multiple
                />
              </b-col>
              <b-col md="2">
                <v-select
                    label="name"
                    v-model="_filter.pre_order_status_ids"
                    :options="_preOrderStatuses"
                    :reduce="item => item.id"
                    multiple
                    class="select-size-sm"
                    placeholder="Ön Sipariş Statüsü"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button-group size="sm">
              <b-button variant="primary" @click="getFilter(1)">
                <feather-icon icon="FilterIcon"></feather-icon>
              </b-button>
              <b-button variant="primary" @click="getToExcel">
                <feather-icon icon="FileIcon"></feather-icon>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>

import axios from "@/libs/axios";
import {mapGetters} from "vuex";
import vSelect from "vue-select";

export default {
  name: "PreOrderFilter",
  components: {vSelect},
  data: () => ({
    show: false
  }),
  computed: {
    ...mapGetters('preOrder', ['_filter']),
    ...mapGetters('preOrderStatus', ['_preOrderStatuses']),
    ...mapGetters('supplier', ['_suppliers']),
  },
  methods: {
    getFilter(page) {
      this.show = true;
      this._filter.page = page;
      this.$store.dispatch('preOrder/getFilter')
          .then(res => {
            this.show = false;
          })
    },
    getToExcel() {
      this.show = true;
      axios.post('/preorder/getexcel', this._filter, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "OnSiparisler"
            link.click()
            URL.revokeObjectURL(link.href)
            this.show = false;
          })
          .catch(() => {
            this.show = false;
            alert('Hata Oldu');
          })
    }
  },
}
</script>


<style scoped>

</style>
