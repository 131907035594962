<template>
  <b-col md="3">
    <pre-order-supplies-supply
        v-for="preOrderSuppliesSupply in preOrderSupplies"
        :pre-order-supplies-supply="preOrderSuppliesSupply"
        :pre-order="preOrder"
        :key="preOrderSuppliesSupply.id"
    />
  </b-col>
</template>

<script>
import PreOrderSuppliesSupply from "@/views/amazon/pre-order/PreOrderSuppliesSupply.vue";

export default {
  name: "PreOrderSupplies",
  components: {PreOrderSuppliesSupply},
  props: {
    preOrder: {
      type: Object,
      required: true,
    },
    preOrderSupplies: {
      type: Array,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>
