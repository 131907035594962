<template>
  <div>
    <PreOrderFilter/>
    <PreOrder
        v-for="preOrder in _preOrders"
        :key="preOrder.id"
        :preOrder="preOrder"
    />
  </div>
</template>

<script>
import PreOrderFilter from "@/views/amazon/pre-order/PreOrderFilter.vue";
import PreOrder from "@/views/amazon/pre-order/PreOrder.vue";
import {mapGetters} from "vuex";

export default {
  name: "PreOrderHome",
  components: {PreOrderFilter, PreOrder},
  computed: {
    ...mapGetters('preOrder', ['_preOrders']),
  },
  mounted() {
    this.$store.dispatch('preOrderStatus/getStatus');
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
