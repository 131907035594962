<template>
  <b-col md="2">
    <div><b>{{ preOrder.id }}</b></div>
    <b-overlay :show="btnShow">
      <b-button size="sm" variant="primary" @click="productSupplierScan">Tara</b-button>
    </b-overlay>

    <div v-if="supplierScan">
      <b-overlay :show="addToBasketBtnShow">
        <b-button-group size="sm" vertical>
          <b-button
              :variant="scan.supplier_id===preOrder.supplier_id?'outline-success':'secondary'"
              v-for="(scan,index) in supplierScan"
              :key="index"
              :disabled="scan.stock<1"
              @click="addToBasket(scan)"
          >
            {{ supplier_name(scan.supplier_id) }} {{ scan.price }} ₺ ({{ scan.stock }}) Ad.
          </b-button>
        </b-button-group>
      </b-overlay>
    </div>

  </b-col>
</template>

<script>
import {mapGetters} from "vuex";
import supplierMixins from "@/mixins/supplierMixins";
import axios from "@/libs/axios";

export default {
  name: "PreOrderBasket",
  mixins: [supplierMixins],
  data: () => ({
    btnShow: false,
    addToBasketBtnShow: false,
  }),
  props: {
    preOrder: {
      required: true,
      type: Object
    },
  },
  computed: {
    ...mapGetters('preOrder', ['_productSupplierScans']),
    supplierScan() {
      let item = this._productSupplierScans.find(item => item.product_id === this.preOrder.product_id);
      if (item) return item.supplierScan;
      return [];
    }
  },
  methods: {
    productSupplierScan() {
      this.btnShow = true;
      this.$store.dispatch('preOrder/productSupplierScan', this.preOrder.product_id)
          .then(res => {
            this.btnShow = false;
          })
    },
    addToBasket(supplier) {
      this.addToBasketBtnShow = true;
      let text = '';
      let icon = '';
      let inputValue = this.preOrder.amount;
      if (supplier.stock < this.preOrder.amount) {
        inputValue = supplier.stock
        text = 'Tedarikçi stok miktarı ön sipariş miktarından azdır.'
        icon = 'warning'
      }

      this.$swal({
        title: 'Kaç adet sepete ekleyelim',
        text,
        icon,
        input: 'number',
        confirmButtonText: 'Sepete Ekle',
        inputValue,
        preConfirm: (amount) => {
          return axios.post('preorder/supply', {
            pre_order_id: this.preOrder.id,
            supplier_id: supplier.supplier_id,
            price: supplier.price,
            amount,
          })
              .then(response => {
                if (response.data.content) {
                  this.preOrder.supplies.push(response.data.content)
                }
                if (response.data.preOrder) {
                  this.preOrder.pre_order_status_id = response.data.preOrder.pre_order_status_id;
                  if (response.data.preOrder.status) {
                    this.preOrder.status = response.data.preOrder.status;
                  }
                }
                return {data: response.data, amount};
              })
              .catch(error => {
                console.log(error)
                alert('Hata')
              })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let amount = supplier.stock - result.value.amount;
          if (amount < 1) amount = 0;
          supplier.stock = amount;
          this.$swal({
            icon: 'success',
            text: 'Sepete ekleme başarılı',
          })
        }
        this.addToBasketBtnShow = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
