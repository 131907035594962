<template>
  <b-col md="3">
    <b-row>
      <b-col md="4">
        <div class="imageContainer m-0 p-0" v-if="preOrderProduct.images[0]">
          <b-img
              class="border border-primary"
              :src="'https://api.kitapfirsatlari.com/' + preOrderProduct.images[0].url"
              width="150"
          />
        </div>
      </b-col>
      <b-col md="8">
        <div>
          <h4>{{ preOrderProduct.name }}</h4>
          <b-badge v-if="preOrderProduct.brand">
            {{ preOrderProduct.brand.name }}
          </b-badge>
          <br>
          <b>{{ preOrderProduct.barcode }}</b> ({{ preOrderProduct.id }})<br>
        </div>

        <div class="mt-2">
          <div>
            Ekleyen :
            <b-badge v-if="preOrderUser">{{ preOrderUser.name }}</b-badge>
          </div>
          <div>
            Ekl. Tarihi:
            <view-date :date="preOrder.created_at" class="d-inline-block"/>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import ViewDate from "@/views/common/ViewDate.vue";

export default {
  name: "PreOrderProduct",
  components: {
    ViewDate
  },
  props: {
    preOrder: {
      required: true,
      type: Object
    },
    preOrderUser: {
      type: Object,
      required: false,
    },
    preOrderProduct: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
