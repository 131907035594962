<template>
  <b-row>
    <b-col md="4" class="m-0 p-0">
      <b-badge>{{ supplier_name(preOrderSuppliesSupply.supplier_id) }}</b-badge>
      <b-badge v-if="preOrderSuppliesSupply.user">{{ preOrderSuppliesSupply.user.short_name }}</b-badge>
    </b-col>
    <b-col md="2" class="m-0 p-0"> {{ preOrderSuppliesSupply.price }} ₺</b-col>
    <b-col md="2" class="m-0 p-0"><b>{{ preOrderSuppliesSupply.amount }}</b></b-col>
    <b-col md="2" class="m-0 p-0"><b>
      <b-button size="sm">Geldi</b-button>
    </b></b-col>
  </b-row>
</template>

<script>
import supplierMixins from "@/mixins/supplierMixins";

export default {
  name: "PreOrderSuppliesSupply",
  mixins: [supplierMixins],
  props: {
    preOrder: {
      type: Object,
      required: true,
    },
    preOrderSuppliesSupply: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>
